<template>
<!--  <div class="row m-0 mt-md-3 mb-md-5">-->
  <div class="row m-0 mt-md-5 mb-md-5 m-auto m-w-85">
    <div class="col-md-3 p-0">
      <div class="card-header text-white pt-5 pb-5 h3 animate__animated animate__slideInDown">资讯详情</div>
    </div>
    <div class="col-md-9 p-0 animate__animated animate__slideInUp">
      <nav aria-label="breadcrumb">
        <ol class="breadcrumb small">
          <li class="breadcrumb-item"><a class="" href="/">首页</a></li>
          <li class="breadcrumb-item active" aria-current="page"><a class="" href="/communication">新闻资讯</a></li>
          <li class="breadcrumb-item active" aria-current="page">{{ newsById.newsTitle }}</li>
        </ol>
      </nav>
      <div class="pl-1 pr-1 pt-3 card">
        <div class="text-left h5">{{newsById.newsTitle}}</div>
        <div class="text-left small">
          <span>{{ newsById.updateTime }}</span><span class="pl-md-3 pr-md-3"> / </span><span>{{ newsById.userId }}</span>
<!--          <span class="pl-md-3 pr-md-3"> / </span><span><i class="fa fa-eye" aria-hidden="true"> 209</i></span>-->
        </div>
        <div class="text-left w-100 mb-md-5 pb-md-5 ql-container ql-snow p-0" style="min-height: 400px">
          <div class="ql-editor" v-html="newsById.news"></div>
        </div>
      </div>
      </div>
  </div>
</template>

<script>
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
export default {
name: "content",
  data() {
    return {
      newId: '',
      newsById: ''
    }
  },
  mounted() {
    this.getNewById(this.$route.query.newId)
  },
  methods: {
    getNewById(newId) {
      this.$http({
        method: 'get',
        url: '/api/cmsReception/getNewsById.html/' + newId,
        data: {}
      }).then((res) => {
        var news = res.data
        this.newsById = news.data
      })
    }
  }
}
</script>

<style scoped>
.div-center {
  margin: 0 auto;
}
</style>